import React from 'react';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../../components/layout';

const Galina = () => (
  <TemplateWrapper>
        <div className="article-content">

                <Helmet>
                        <title>Наша команда - Галина Кудинова</title>
                        <meta name="description" content="Индивидуальный мастер по наращиванию - Кудинова Галина" />
                        <meta name="keywords" content="кудинова галина, галя к, мастер по наращиванию, лучший мастер в москве" />
                </Helmet>

                <h1 className="hm-title-1">Галина Кудинова</h1>
                <img src="/img/photos/galina/photo2.jpg" className="img-fluid float-md-right img-thumbnail" alt="Фотография Галины" />

                <p>Привет, меня зовут Галина, и я профессионально занимаюсь наращиванием волос, являюсь дипломированным технологом-колористом с опытом работы более 10 лет. Я прошла обучение в известной немецкой компании <b>HAIR TALK</b>, которая стоит у истоков ленточного наращивания и дает бесценный опыт стилистам и парикмахерам. Огромная часть моих постоянных клиентов - медийные персоны - люди которые должны выглядеть на все сто 24 часа в сутки.</p>
                <p>Я провожу все виды наращивания волос, в том числе используя сложные методики и современные технологии, всегда нахожу баланс между эффектностью и безопасностью. У меня богатый практический опыт, умею находить общий язык с клиентами и всегда предлагаю им именно то, что им нужно и что решит их проблемы с волосами и преобразит внешний вид. </p>

                <p>В наращивании волос главное: </p>
                <ul className="hm-list">
                        <li>Правильно подобранный тип волос</li>
                        <li>Идеальное распределение прядей</li>
                        <li>Гармоничное количество донорских волос</li>
                        <li>Нужный угол крепления капсулы или ленты</li>
                </ul>

                <p>Более 4 лет я работала топ-стилистом в самом престижном салоне Москвы, мы активно использовали итальянскую горячую технику наращивания волос и различные сложные окрашивания: такие техники как <b>Шатуш</b>, <b>Балаяж</b> на наращенные волосы. Закончила брашинг-курс по укладкам, который действительно помогает пересмотреть подход к профессии, учит делать потрясающие укладки с помощью фена и брашинга буквально за 10-15 минут. Обучение подарило мне много новых знаний, которые я смогла легко подкрепить личным опытом.
        </p>

                {/* <p>Также я прошла углубленный курс по трихологии, ознакомилась с информацией о причинах и видах облысения, освоила различные методы решения этой деликатной проблемы. Алопеция требует комплексного подхода, и я стараюсь сделать всё возможное для того чтобы остановить выпадение волос. Эффективна при алопеции мезотерапия — метод инъекционного введения питательных и полезных веществ, которые восполняют нехватку витаминов, гиалуроновой кислоты, а также стимулируют процесс самовосстановления благодаря местному раздражающему действию и усилению кровотока.</p> */}

                <p>Несмотря на мой богатый опыт, я не стою на месте и регулярно посещаю мастер-классы, знакомлюсь с работами своих коллег и стилистов с мировым именем. Это дает стимул развиваться дальше, узнавать что-то новое и искать иные способы решения проблем, которые могут возникать при работе с волосами. Мне посчастливилось работать не только в России, но и за рубежом. Отлично знаю красители: <b>Organic systems</b>, <b>Revlon</b>, <b>Wella</b>, <b>Chi</b>, <b>Emme Diciotto</b>, <b>Loreal</b>, <b>Milk Shake</b>. Последние 5 лет я работаю на японском красителе Lebel.

Всегда стремлюсь к достижению идеального результата из возможного!</p>

                <p>Мой девиз - Совершенство - в деталях. С нетерпением жду Вас в нашем салоне!</p>

                <img src="/img/photos/galina/cert4.jpg" alt="Certificate" className="img-fluid" />
                <img src="/img/photos/galina/cert1.jpg" alt="Certificate" className="img-fluid pt-3" />
                <img src="/img/photos/galina/cert2.jpg" alt="Certificate" className="img-fluid pt-3" />
                <img src="/img/photos/galina/cert3.jpg" alt="Certificate" className="img-fluid pt-3" />

                <img src="/img/photos/galina/cert5.jpg" alt="Certificate" className="img-fluid pt-3" />
                <img src="/img/photos/galina/cert7.jpg" alt="Certificate" className="img-fluid pt-3" />
                <img src="/img/photos/galina/cert6.jpg" alt="Certificate" className="img-fluid pt-3 pb-3" />

        </div>
</TemplateWrapper>
)

export default Galina